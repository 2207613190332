
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFormField, PDFFormField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { FormSubmissionValidationError, FormValue } from 'client-website-ts-library/types/Forms';

import { PDFFormSubfieldChangedData } from '@/app_code/Forms';

import FormGraphicalPDFTextSubfield from './FormGraphicalPDFTextSubfield.vue';
import FormGraphicalPDFFieldCheckbox from './FormGraphicalPDFFieldCheckbox.vue';

@Component({
  components: {
    FormGraphicalPDFTextSubfield,
    FormGraphicalPDFFieldCheckbox,
  },
})
export default class FormGraphicalPDFSubfield extends Vue implements IFormField {
  @Prop()
  private readonly def!: PDFFormField;

  @Prop()
  private readonly parentKey!: string;

  @Prop()
  private readonly errors!: FormSubmissionValidationError[];

  getValue(): FormValue {
    return (this.$refs.field as unknown as IFormField).getValue();
  }

  setValue(value: FormValue) {
    (this.$refs.field as unknown as IFormField).setValue(value);
  }

  handleChanged(data: PDFFormSubfieldChangedData): void {
    this.$emit('change', data);
  }
}
